import AppliedRoute from "./components/AppliedRoute";
import Login from "./containers/Login";
import Home from "./Home";
import { Route, Switch } from "react-router-dom";
import UserList from "./UserList";
import UserEdit from "./UserEdit";
import React from "react";
import NotFound from "./containers/NotFound";

const Routes = ({ appProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} appProps={appProps} />
    <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
    <AppliedRoute
      path="/vcdausers"
      exact
      component={UserList}
      appProps={appProps}
    />
    <AppliedRoute path="/users/:id" component={UserEdit} appProps={appProps} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
export default Routes;
