import React, { Component } from "react";
import { Button, ButtonGroup, Container } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Table from "react-bootstrap/Table";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isLoading: true,
      isAuthenticated: props.isAuthenticated,
      props: props
    };
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    const token = this.props.userToken;
    if (token != null && token !== "" && token !== undefined) {
      try {
        this.setState({ isLoading: true });
        const apiUrl = this.props.userManagementApiServicesUrl;
        sessionStorage.setItem(
          "userManagementApiServicesUrl",
          this.props.userManagementApiServicesUrl
        );
        const headers = new Headers({
          Authorization: token,
          "Content-Type": "application/json"
        });
        fetch(apiUrl + "/users", {
          headers: headers,
          mode: "cors"
        })
          .then(response => response.json())
          .then(data => this.setState({ users: data, isLoading: false }))
          .catch(err => console.log(err));
      } catch (e) {
        console.log(e);
      }
    } else {
      this.clearSessionAttributes();
    }
  }

  clearSessionAttributes = () => {
    this.props.userHasAuthenticated(false);
    this.props.setUserToken("");
    this.props.setUserManagementApiServicesUrl("");
    this.props.setWebClientId("");
    this.props.setUserPoolId("");
    sessionStorage.clear();
  };

  async remove(id) {
    const token = this.props.userToken;
    if (token != null && token !== "") {
      try {
        const apiUrl = this.props.userManagementApiServicesUrl;
        await fetch(apiUrl + `/users/${id}`, {
          method: "DELETE",
          mode: "cors",
          headers: {
            Authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(() => {
            let updatedUsers = [...this.state.users].filter(
              i => JSON.parse(i).id !== id
            );
            this.setState({ users: updatedUsers });
          })
          .catch(err => console.log(err));
      } catch (e) {
        console.log(e);
      }
    } else {
      this.props.history.push("/login");
    }
  }

  render() {
    const { users, isLoading, isAuthenticated } = this.state;
    if (isAuthenticated) {
      if (isLoading) {
        return <p>Loading...</p>;
      }

      const userList = users.map(userItem => {
        const user = JSON.parse(userItem);
        return (
          <tr key={user.id}>
            <td>{user.accountid}</td>
            <td>{user.email}</td>
            <td>{user.authType}</td>
            <td>
              {user.roles === undefined || user.roles === null
                ? user.roles
                : Array.from(user.roles).length > 3
                ? user.roles
                : Array.from(user.roles).join()}
            </td>
            <td>
              <ButtonGroup>
                <Button
                  size="sm"
                  color="primary"
                  tag={Link}
                  to={"/users/" + user.id}
                >
                  Edit
                </Button>
                <Button
                  size="sm"
                  color="danger"
                  onClick={() =>
                    window.confirm(
                      "Are you sure you wish to delete this user?"
                    ) && this.remove(user.id)
                  }
                >
                  Delete
                </Button>
              </ButtonGroup>
            </td>
          </tr>
        );
      });

      return (
        <div>
          <Container fluid>
            <div className="float-right">
              <Button color="success" tag={Link} to="/users/new">
                Add User
              </Button>
            </div>
            <h3>Manage VCDA Users</h3>
            <Table className="mt-4" responsive="sm" striped bordered hover>
              <thead>
                <tr>
                  <th width="25%">Account ID</th>
                  <th width="25%">Email</th>
                  <th width="20%">Authentication Mode</th>
                  <th width="25%">Roles</th>
                  <th width="5%"></th>
                </tr>
              </thead>
              <tbody>{userList}</tbody>
            </Table>
          </Container>
        </div>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}

export default UserList;
