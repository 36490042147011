import React, { Component } from "react";
import "./App.css";
import { Link, Redirect } from "react-router-dom";
import { Button, Container } from "reactstrap";
import Table from "react-bootstrap/Table";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      users: [],
      isAuthenticated: props.isAuthenticated,
      props: props
    };
  }

  async componentDidMount() {
    const token = this.props.userToken;

    if (token != null && token !== undefined && token !== "") {
      try {
        const headers = new Headers({
          Authorization: token,
          "Content-Type": "application/json"
        });
        const apiUrl = this.props.userManagementApiServicesUrl;
        sessionStorage.setItem(
          "userManagementApiServicesUrl",
          this.props.userManagementApiServicesUrl
        );
        const response = await fetch(apiUrl + "/users", {
          headers: headers,
          mode: "cors"
        });
        const body = await response.json();
        this.setState({ users: body, isLoading: false });
      } catch (e) {
        console.log(e);
      }
    } else {
      this.clearSessionAttributes();
    }
  }

  clearSessionAttributes = () => {
    this.props.userHasAuthenticated(false);
    this.props.setUserToken("");
    this.props.setUserManagementApiServicesUrl("");
    this.props.setWebClientId("");
    this.props.setUserPoolId("");
    sessionStorage.clear();
  };

  render() {
    const { users, isLoading, isAuthenticated } = this.state;
    if (isAuthenticated) {
      if (isLoading) {
        return <p>Loading...</p>;
      }
      return (
        <div>
          <Container fluid>
            <Button color="link">
              <Link to="/vcdausers">
                <h1>VCDA User Management Dashboard</h1>
              </Link>
            </Button>

            <Table className="mt-4" responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Account ID</th>
                  <th>Email</th>
                  <th>Authentication Mode</th>
                  <th>Roles</th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={JSON.parse(user).id}>
                    <td>{JSON.parse(user).accountid}</td>
                    <td>{JSON.parse(user).email}</td>
                    <td>{JSON.parse(user).authType}</td>
                    <td>
                      {JSON.parse(user).roles === undefined ||
                      JSON.parse(user).roles === null
                        ? JSON.parse(user).roles
                        : Array.from(JSON.parse(user).roles).length > 3
                        ? JSON.parse(user).roles
                        : Array.from(JSON.parse(user).roles).join()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </div>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}

export default Home;
