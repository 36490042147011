import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./Login.css";
import { Auth } from "aws-amplify";
import loadConfigAndParameters from "../loadconfigparams-exports";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      await loadConfigAndParameters(
        props.userManagementApiServicesUrl,
        props.userPoolId,
        props.webClientId,
        props.setUserPoolId,
        props.setWebClientId,
        props.setUserManagementApiServicesUrl
      );
      await Auth.signIn(email, password)
        .then(response => {
          const jwtToken = response
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken();
          props.setUserToken(jwtToken);
          sessionStorage.setItem("userToken", jwtToken);
        })
        .catch(err => console.log(err));
      props.userHasAuthenticated(true);
      sessionStorage.setItem("isAuthenticated", true);
      props.history.push("/");
    } catch (e) {
      alert("Error : " + e.message);
    }
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bssize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bssize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button block bssize="large" disabled={!validateForm()} type="submit">
          Login
        </Button>
      </form>
    </div>
  );
}
