import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";

class UserEdit extends Component {
  emptyItem = {
    id: "",
    password: "",
    accountid: "",
    email: "",
    authType: "",
    roles: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      item: this.emptyItem,
      action: "",
      roleList: ["ROLE_Account", "ROLE_Vehicle", "ROLE_Incentive"],
      authTypeList: ["Token", "Basic"],
      isAuthenticated: props.isAuthenticated,
      props: props
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.toggleRoleChange = this.toggleRoleChange.bind(this);
    this.populateAuthTypeList = this.populateAuthTypeList.bind(this);
    this.populateRoleList = this.populateRoleList.bind(this);
    this.populateAuthTypeValue = this.populateAuthTypeValue.bind(this);
    this.populateRolesValue = this.populateRolesValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = async () => {
    const token = this.props.userToken;
    if (token != null && token !== "" && token !== undefined) {
      try {
        const headers = new Headers({
          Authorization: token,
          "Content-Type": "application/json"
        });
        if (this.props.match.params.id !== "new") {
          const apiUrl = this.props.userManagementApiServicesUrl;
          const user = await (await fetch(
            apiUrl + `/users/${this.props.match.params.id}`,
            {
              headers: headers,
              mode: "cors"
            }
          )).json();
          const userParsed = JSON.parse(user);
          this.setState({ item: userParsed, action: "Edit User" });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      this.props.userHasAuthenticated(false);
      sessionStorage.removeItem("isAuthenticated");
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item: item });
  };

  toggleChange = event => {
    const value = event.value;
    const name = "authType";
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item: item });
  };

  toggleRoleChange = event => {
    const name = "roles";
    let item = { ...this.state.item };
    var roles = [];
    if (event !== null) {
      for (var i = event.length - 1; i >= 0; i--) {
        roles.push(event[i].value);
      }
    } else {
      roles = null;
    }
    item[name] = roles;
    this.setState({ item: item });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const token = this.props.userToken;
    if (token != null && token !== "" && token !== undefined) {
      try {
        const { item, action } = this.state;
        const apiUrl = this.props.userManagementApiServicesUrl;
        sessionStorage.setItem(
          "userManagementApiServicesUrl",
          this.props.userManagementApiServicesUrl
        );
        await fetch(apiUrl + "/users", {
          method: action === "Edit User" ? "PUT" : "POST",
          mode: "cors",
          headers: {
            Authorization: token,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(item, function replaceUndefinedOrNull(
            key,
            value
          ) {
            if (value === null || value === undefined || value === "") {
              return undefined;
            }
            return value;
          })
        });
        this.props.history.push("/vcdausers");
      } catch (e) {
        console.log(e);
      }
    } else {
      this.clearSessionAttributes();
    }
  };

  clearSessionAttributes = () => {
    this.props.userHasAuthenticated(false);
    this.props.setUserToken("");
    this.props.setUserManagementApiServicesUrl("");
    this.props.setWebClientId("");
    this.props.setUserPoolId("");
    sessionStorage.clear();
  };

  populateAuthTypeValue = item => {
    var authTypeValue;
    if (item.authType !== null && item.authType !== "") {
      authTypeValue = { label: item.authType, value: item.authType };
    } else {
      authTypeValue = null;
    }
    return authTypeValue;
  };

  populateRolesValue = item => {
    var rolesValue = [];
    if (!Array.isArray(item.roles) || !item.roles.length) {
      rolesValue = null;
    } else {
      rolesValue = this.populateRoleList(item.roles);
    }
    return rolesValue;
  };

  populateRoleList = roleList => {
    var roles = [];
    for (var i = roleList.length - 1; i >= 0; i--) {
      const role = {
        label: roleList[i],
        value: roleList[i]
      };
      roles.push(role);
    }
    return roles;
  };

  populateAuthTypeList = authTypeList => {
    var authTypes = [];
    for (var i = authTypeList.length - 1; i >= 0; i--) {
      const authType = {
        label: authTypeList[i],
        value: authTypeList[i]
      };
      authTypes.push(authType);
    }
    return authTypes;
  };

  render = () => {
    const {
      item,
      action,
      roleList,
      authTypeList,
      isAuthenticated
    } = this.state;
    const title = <h2>{item.id ? "Edit User" : "Add User"}</h2>;
    var authTypes = this.populateAuthTypeList(authTypeList);
    var roles = this.populateRoleList(roleList);
    var authTypeValue = this.populateAuthTypeValue(item);
    var rolesValue = this.populateRolesValue(item);

    if (isAuthenticated) {
      return (
        <div>
          <Container>
            {title}
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label for="username">Username/Token</Label>
                <Input
                  type="text"
                  name="id"
                  id="id"
                  defaultValue={item.id || ""}
                  onChange={this.handleChange}
                  autoComplete="id"
                  disabled={action === "Edit User" ? "disabled" : false}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  type="text"
                  name="password"
                  id="password"
                  value={item.password || ""}
                  onChange={this.handleChange}
                  autoComplete="password"
                />
              </FormGroup>
              <FormGroup>
                <Label for="accountid">Account ID</Label>
                <Input
                  type="text"
                  name="accountid"
                  id="accountid"
                  value={item.accountid || ""}
                  onChange={this.handleChange}
                  autoComplete="accountid"
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email Address</Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={item.email || ""}
                  onChange={this.handleChange}
                  autoComplete="email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="authType">Authentication Mode</Label>
                <Select
                  name="authType"
                  id="authType"
                  options={authTypes}
                  onChange={this.toggleChange}
                  value={authTypeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label for="roles">Roles</Label>
                <Select
                  name="roles"
                  id="roles"
                  options={roles}
                  onChange={this.toggleRoleChange}
                  value={rolesValue}
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <Button color="primary" type="submit">
                  Save
                </Button>{" "}
                <Button color="secondary" tag={Link} to="/vcdausers">
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      );
    } else {
      return <Redirect to="/login" />;
    }
  };
}

export default withRouter(UserEdit);
