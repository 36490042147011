import appConfig from "./appconfig-exports";

function isNullOrEmpty(value) {
  return value == null || value === undefined || value === "";
}

async function loadConfigAndParameters(
  userManagementApiServicesUrl,
  userPoolId,
  webClientId,
  setUserPoolId,
  setWebClientId,
  setUserManagementApiServicesUrl
) {
  if (
    isNullOrEmpty(userManagementApiServicesUrl) ||
    isNullOrEmpty(userPoolId) ||
    isNullOrEmpty(webClientId)
  ) {
    const userPoolId = appConfig.userPoolId;
    setUserPoolId(userPoolId);
    sessionStorage.setItem("userPoolId", userPoolId);
    const webClientId = appConfig.webClientId;
    setWebClientId(webClientId);
    sessionStorage.setItem("webClientId", webClientId);
    const userManagementApiServicesUrl = appConfig.userManagementApiServicesUrl;
    setUserManagementApiServicesUrl(userManagementApiServicesUrl);
    sessionStorage.setItem(
      "userManagementApiServicesUrl",
      userManagementApiServicesUrl
    );
  }
}

export default loadConfigAndParameters;
