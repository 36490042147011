import React, { useEffect, useState } from "react";
import "./App.css";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import AppNavbar from "./AppNavbar";
import { Nav, Navbar, NavbarBrand, NavItem } from "reactstrap";
import Amplify from "aws-amplify";
import appConfig from "./appconfig-exports";
import loadConfigAndParameters from "./loadconfigparams-exports";

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") || false
  );
  const [userToken, setUserToken] = useState(
    sessionStorage.getItem("userToken") || ""
  );
  const [
    userManagementApiServicesUrl,
    setUserManagementApiServicesUrl
  ] = useState(sessionStorage.getItem("userManagementApiServicesUrl") || "");
  const [userPoolId, setUserPoolId] = useState(
    sessionStorage.getItem("userPoolId") || ""
  );
  const [webClientId, setWebClientId] = useState(
    sessionStorage.getItem("webClientId") || ""
  );

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await loadConfigAndParameters(
        userManagementApiServicesUrl,
        userPoolId,
        webClientId,
        setUserPoolId,
        setWebClientId,
        setUserManagementApiServicesUrl
      );
      const amplifyConfig = {
        Auth: {
          mandatorySignIn: true,
          region: appConfig.region,
          userPoolId: sessionStorage.getItem("userPoolId"),
          userPoolWebClientId: sessionStorage.getItem("webClientId")
        },
        region: appConfig.region
      };
      Amplify.configure(amplifyConfig);
      await Auth.currentSession().then(session => {
        const jwtToken =
          session != null && session.getIdToken().getJwtToken() != null
            ? session.getIdToken().getJwtToken()
            : "";
        setUserToken(jwtToken);
        sessionStorage.setItem("userToken", jwtToken);
      });
      userHasAuthenticated(true);
      sessionStorage.setItem("isAuthenticated", "true");
      props.history.push("/");
    } catch (e) {
      if (e !== "No current user") {
        console.log("App Error : ", e);
        alert("Please sign in with your email address and password");
      }
    }
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setUserToken("");
    setUserManagementApiServicesUrl("");
    setWebClientId("");
    setUserPoolId("");
    sessionStorage.clear();
    props.history.push("/login");
  }

  return (
    <>
      <AppNavbar />
      <Navbar fluid="true" expand="md">
        <Nav className="ml-auto" navbar>
          <NavItem>
            {isAuthenticated ? (
              <NavbarBrand tag={Link} to="/login" onClick={handleLogout}>
                Logout
              </NavbarBrand>
            ) : (
              <>
                {props.location.pathname.match("/login") ? null : (
                  <NavbarBrand tag={Link} to="/login">
                    Login
                  </NavbarBrand>
                )}
              </>
            )}
          </NavItem>
        </Nav>
      </Navbar>
      <Routes
        appProps={{
          isAuthenticated,
          userHasAuthenticated,
          userToken,
          setUserToken,
          userPoolId,
          setUserPoolId,
          webClientId,
          setWebClientId,
          userManagementApiServicesUrl,
          setUserManagementApiServicesUrl
        }}
      />
    </>
  );
}

export default withRouter(App);
