const appConfig = {
  alksUrl: process.env.REACT_APP_ALKS_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  region: process.env.REACT_APP_AWS_REGION,
  userManagementApiServicesUrl: process.env.REACT_APP_API_SERVICES_URL,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  webClientId: process.env.REACT_APP_WEB_CLIENT_ID
};

export default appConfig;
